import http from "./http-common";
import ConferenceDetailModel from "../models/home/conference-detail";
import PaperTopicModel from "../models/home/paper-topic";
import ImportantDateModel from "../models/home/important-date";

export default new (class HomeService {
  async getConferenceFiles() {
    return await http
      .get("/conference-files/1?fields[0]=name&populate=*")
      .then(async (response) => {
        return response.data.data;
      })
      .catch((error) => {
        console.log(error);
      });
  }

  async getFullTemplateFiles() {
    return await http
      .get("/documents/2?populate=*")
      .then(async (response) => {
        return response.data.data;
      })
      .catch((error) => {
        console.log(error);
      });
  }

  async getConferenceDetail() {
    return await http
      .get(
        "/conference-details/1?fields[0]=conference_name&fields[1]=conference_abbr&fields[2]=conference_detail&populate=*"
      )
      .then(async (response) => {
        const data = await ConferenceDetailModel.get(response.data.data);

        return data;
      })
      .catch((error) => {
        console.log(error);
      });
  }

  async getPaperTopic() {
    return await http
      .get("/paper-topics?fields[0]=name&populate=*")
      .then(async (response) => {
        const data = await Promise.all(
          response.data.data.map((item) => PaperTopicModel.get(item))
        );

        return data;
      })
      .catch((error) => {
        console.log(error);
      });
  }

  async getImportantDate() {
    return await http
      .get(
        "/important-dates?fields[0]=start_date&fields[1]=end_date&fields[3]=note&fields[4]=status&fields[5]=detail&populate=*"
      )
      .then(async (response) => {
        const sortedData = await Promise.all(
          response.data.data.map((item) => ImportantDateModel.get(item))
        ).then((data) => {
          data.sort((a, b) => {
            const dateA = new Date(a.start_date);
            const dateB = new Date(b.start_date);
            return dateA - dateB;
          });
          return data;
        });

        return sortedData;
      })
      .catch((error) => {
        console.log(error);
      });
  }
})();
