export default new (class CommitteeModel {
  // static API_URL = process.env.REACT_APP_API_URL;

  async get(data) {
    const model = {};

    model.id = data.id || 0;
    model.title = data.attributes.title || "";
    model.firstname = data.attributes.firstname || "";
    model.lastname = data.attributes.lastname || "";
    model.position1 = data.attributes.position1 || "";
    model.position2 = data.attributes.position2 || "";
    model.rank = parseInt(data.attributes.note) || "";

    return model;
  }
})();
