export default new (class SpeakerModel {
  // static API_URL = process.env.REACT_APP_API_URL;

  async get(data) {
    const model = {};

    model.id = data.id || 0;
    model.session_name = data.attributes.session_name || "";
    model.speaker = data.attributes.speaker || null;

    return model;
  }
})();
