import React, { useEffect, useState } from "react";
import registrationPic from "../../assets/images/registration/registration.png";
import PrimaryButton from "../../components/button/PrimaryButton";
// import SecondaryButton from "../../components/button/SecondaryButton";
import ComingSoon from "../../components/ComingSoon";
import RegistrationService from "../../services/registration";

export default function Registration() {
  const active = true;

  useEffect(() => {
    window.scrollTo(0, 0);
    getRegistrationData();
    getRegistrationHeader();
  }, []);

  let [registrationData, setRegistrationData] = useState([]);
  let [registrationHeader, setRegistrationHeader] = useState(null);

  const getRegistrationData = async () => {
    try {
      let res = await RegistrationService.getTable();
      setRegistrationData(res);
    } catch {
      console.error();
    }
  };

  const getRegistrationHeader = async () => {
    try {
      let res = await RegistrationService.getHeaderTable();
      setRegistrationHeader(res);
    } catch {
      console.error();
    }
  };

  return (
    <div id="programme" className="page-padding">
      {!active ? (
        <ComingSoon title="registration" />
      ) : (
        <div>
          <div className="bg-darkGray section">
            <div className="page-container">
              <div className="flex items-center justify-center lg:justify-between py-[28px] pb-[80px] lg:pb-[0px] pt-[38px]">
                <h1 className="text-[32px] sm:text-[40px] md:text-[96px] text-white font-bold leading-[116px] text-center lg:text-left">
                  REGISTRATION
                </h1>
                <img
                  className="registration-img hidden lg:block w-[340px]"
                  src={registrationPic}
                  alt=""
                />
              </div>
            </div>
          </div>

          <div className="section py-[68px]">
            <div className="page-container overflow-hidden">
              <h1 className="uppercase text-[32px] sm:text-[40px] font-bold text-darkGray text-center lg:text-left">
                Registration and fees
              </h1>

              <div className="overflow-x-auto">
                <table className="w-full mt-[53px]">
                  <tbody>
                    {registrationHeader && (
                      <tr className="uppercase bg-darkRed text-white text-[18px] lg:text-[24px] font-bold border-darkRed border-[1px] px-[26px] h-[150px]">
                        <th className="w-fit lg:w-[20%]"></th>
                        <th className="w-fit lg:w-[20%] border-white border-x-[1px]">
                          {registrationHeader.header[0].name}
                        </th>
                        <th className="w-fit lg:w-[20%] border-white border-x-[1px]">
                          {registrationHeader.header[1].name}
                        </th>
                        <th className="w-fit lg:w-[20%] border-white border-x-[1px]">
                          {registrationHeader.header[2].name}
                        </th>
                        <th className="w-fit lg:w-[20%] ">
                          {registrationHeader.header[3].name}
                        </th>
                      </tr>
                    )}
                    {registrationData &&
                      registrationData.map((item, i) => (
                        <tr
                          key={i}
                          className="uppercase text-[20px] text-darkGray font-bold border-darkRed border-[1px] h-[80px]"
                        >
                          <td className="text-left p-[20px]">{item.type}</td>
                          {item.ticket.map((price, i) => (
                            <td
                              key={i}
                              className="break-all border-darkRed border-[1px]"
                            >
                              {price.ticket_price} USD
                            </td>
                          ))}
                        </tr>
                      ))}
                  </tbody>
                </table>
              </div>

              <p className="whitespace-pre-line text-darkGray text-[16px] font-bold text-left mt-[18px]">
                * CLMVT: Cambodia, Laos, Myanmar, Vietnam, and Thailand
                <br />
                **Excursion will be arranged with additional cost.
              </p>

              {/* <p className="text-darkRed font-bold text-[20px] text-center mt-[20px]">
                The registration will be available soon
              </p> */}

              <div className="mt-[15px] section">
                <PrimaryButton
                  text="register now"
                  function={() => {
                    window.open(
                      "https://apss2023.innosoft.kmutt.ac.th/conference",
                      "_blank"
                    );
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
