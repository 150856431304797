import React, { useEffect, useState } from "react";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import FooterService from "../services/footer";
// import {
//   faFacebookF,
//   faLinkedinIn,
//   faTwitter,
// } from "@fortawesome/free-brands-svg-icons";

export default function Footer() {
  let [footerData, setFooterData] = useState(null);

  useEffect(() => {
    getFooterData();
  }, []);

  const getFooterData = async () => {
    try {
      let res = await FooterService.getFooter();
      setFooterData(res);
    } catch {
      console.error();
    }
  };


  return (
    <>
      {footerData && (
        <div id="footer" className="section bg-darkGray w-full shadow-lg">
          <div className="navbar-container pt-[45px] pb-[260px] flex items-center justify-between">
            <div className="flex flex-col gap-[30px] lg:grid grid-cols-1 lg:grid-cols-5 w-full items-center lg:items-start">
              <div className="text-center lg:text-left col-span-1">
                <p className="text-[24px] text-white font-bold uppercase">
                  Contact Us
                </p>
                <p className="text-[16px] text-white font-normal mt-[10px]">
                  {footerData.contact}
                </p>
              </div>

              <div className="text-center col-span-3">
                <p className="text-[24px] text-white font-bold uppercase">
                  Hosts
                </p>
                <div className="mt-[10px] flex-wrap flex items-center justify-center gap-[20px]">
                  {footerData.hosts.map((item, i) => (
                    <img
                      onClick={() => {
                        if (item && item.url)
                          // eslint-disable-next-line
                          window.open(
                            // eslint-disable-next-line
                            `${item.url.replace(/[^\x00-\x7F]/g, "")}`,
                            "_blank"
                          );
                      }}
                      className="w-[100px]"
                      key={i}
                      src={`${process.env.REACT_APP_API_URL}${item.image.data.attributes.url}`}
                      alt=""
                    />
                  ))}
                </div>
              </div>

              <div className="text-center flex-wrap lg:text-right col-span-1 flex flex-row lg:flex-col gap-[15px]">
                {footerData.menu.map((item, i) => (
                  <h1
                    key={i}
                    className="text-white text-right text-[16px] font-bold"
                    onClick={() => {
                      window.open(item.href, "_self");
                    }}
                  >
                    {item.label}
                  </h1>
                ))}
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
