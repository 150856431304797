export default new (class FooterModel {
  // static API_URL = process.env.REACT_APP_API_URL;

  async get(data) {
    const model = {};

    model.id = data.id || 0;
    model.contact = data.attributes.contact_us || "";
    model.hosts = data.attributes.hosts || [];
    model.menu = data.attributes.menu || [];

    return model;
  }
})();
