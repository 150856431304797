export default new (class HostModel {
  // static API_URL = process.env.REACT_APP_API_URL;

  async get(data) {
    const model = {};

    model.id = data.id || 0;
    model.name = data.attributes.name || "";
    model.url = data.attributes.url || "";
    model.image = data.attributes.image.data[0].attributes.url || "";

    if (data.attributes.image && data.attributes.image.data[1]) {
      model.image2 = data.attributes.image.data[1].attributes.url || null;
    }

    return model;
  }
})();
