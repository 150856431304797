import http from "./http-common";
import RegistrationModel from "../models/registration/registration";
import RegistrationHeaderModel from "../models/registration/registrationHeader";

export default new (class RegistrationService {
  async getTable() {
    return await http
      .get("/registration-fees?fields[0]=register_type&populate=*")
      .then(async (response) => {
        const data = await Promise.all(
          response.data.data.map((item) => RegistrationModel.get(item))
        );

        return data;
      })
      .catch((error) => {
        console.log(error);
      });
  }

  async getHeaderTable() {
    return await http
      .get("/header-registrations/1?&populate=*")
      .then(async (response) => {
        const data = await RegistrationHeaderModel.get(response.data.data);

        return data;
        // return data.header.sort((a, b) => a.order - b.order);
      })
      .catch((error) => {
        console.log(error);
      });
  }
})();
