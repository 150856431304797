import React, { useEffect } from "react";
import ComingSoon from "../../components/ComingSoon";

export default function Sponsors() {
  const active = true;

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div id="privacy" className="page-padding">
      {!active ? (
        <ComingSoon title="privacy" />
      ) : (
        <div>
          <div className="bg-darkGray section">
            <div className="page-container">
              <div className="flex items-center justify-center py-[60px] md:py-[130px]">
                <div>
                  <h1 className="text-[32px] sm:text-[40px] md:text-[96px] text-white font-bold text-center">
                    PRIVACY POLICY
                  </h1>
                </div>
              </div>
            </div>
          </div>

          <div className="bg-white section py-[80px]">
            <div className="page-container">
              <p className="font-bold text-[18px] md:text-[24px] text-left">
                Privacy Policy
              </p>
              <ul className="mt-[10px] pl-[30px] list-disc text-left font-normal text-[16px] md:text-[20px] leading-[40px]">
                <li>
                  Department of Chemical Engineering, KMUTT is committed to
                  using responsibly any private information submitted by
                  visitors to its site:{" "}
                  <span
                    className="break-all underline"
                    onClick={() => {
                      window.open(
                        "https://apss2023.innosoft.kmutt.ac.th/",
                        "_blank"
                      );
                    }}
                  >
                    https://apss2023.innosoft.kmutt.ac.th/.
                  </span>
                  We are the sole owner of the information collected on this
                  site and will not sell, share or rent this information to
                  others.
                </li>
                <li>
                  E-Mail Information Service: This personal information will not
                  be used to send unsolicited information unless the subscriber
                  specifically allows it, will not be provided to any outside
                  party and will not be used to track the movements of visitors.
                </li>
                <li>
                  Cookies: Cookies allow users to access our site more
                  accessible and to use the site’s features by not entering a
                  password each time the site is visited; users may reject using
                  cookies and have access to the site features that do not
                  require cookies. Cookies are not used to track the movements
                  of visitors.
                </li>
                <li>
                  Security: When on a secure page, the lock/unlock icon on your
                  browser window becomes locked. When visitors submit sensitive
                  information (such as credit card details) to make on-site
                  purchases, the information is protected with encryption
                  software. To learn more about this protection, visit
                  <span
                    className="break-all underline"
                    onClick={() => {
                      window.open(
                        "https://en.wikipedia.org/wiki/Transport_Layer_Security",
                        "_blank"
                      );
                    }}
                  >
                    https://en.wikipedia.org/wiki/Transport_Layer_Security.
                  </span>
                  The credit card processing company does not retain, share,
                  store or use personally identifiable information for any
                  secondary purposes. Information collected on the registration
                  form is used for billing purposes and to complete
                  registration. If we have problems processing an registration,
                  this information may be used to contact the purchaser.
                </li>
                <li>
                  Internet Protocol (IP) Addresses: IP addresses are used to
                  analyze trends and administer the site. IP addresses are not
                  linked to personally identifiable information. Links: This
                  site contains links to other sites; We are not responsible for
                  the privacy practices of those sites.
                </li>
              </ul>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
