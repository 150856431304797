import http from "../http-common";
import CommiteeModel from "../../models/about/committee";

export default new (class CommitteeService {
  async getJapan() {
    return await http
      .get(
        "/comittees?filters[type][$eq]=1&fields[0]=title&fields[1]=firstname&fields[2]=lastname&fields[3]=position1&fields[4]=position2&fields[5]=type&fields[6]=note"
      )
      .then(async (response) => {
        const data = await Promise.all(
          response.data.data.map((item) => CommiteeModel.get(item))
        );

        return data.sort((a, b) => a.rank - b.rank);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  async getKorea() {
    return await http
      .get(
        "/comittees?filters[type][$eq]=2&fields[0]=title&fields[1]=firstname&fields[2]=lastname&fields[3]=position1&fields[4]=position2&fields[5]=type&fields[6]=note"
      )
      .then(async (response) => {
        const data = await Promise.all(
          response.data.data.map((item) => CommiteeModel.get(item))
        );

        return data.sort((a, b) => a.rank - b.rank);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  async getThai() {
    return await http
      .get(
        "/comittees?filters[type][$eq]=3&fields[0]=title&fields[1]=firstname&fields[2]=lastname&fields[3]=position1&fields[4]=position2&fields[5]=type&fields[6]=note"
      )
      .then(async (response) => {
        const data = await Promise.all(
          response.data.data.map((item) => CommiteeModel.get(item))
        );

        return data.sort((a, b) => a.rank - b.rank);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  async getAcademic() {
    return await http
      .get(
        "/comittees?filters[type][$eq]=4&fields[0]=title&fields[1]=firstname&fields[2]=lastname&fields[3]=position1&fields[4]=position2&fields[5]=type&fields[6]=note"
      )
      .then(async (response) => {
        const data = await Promise.all(
          response.data.data.map((item) => CommiteeModel.get(item))
        );

        return data.sort((a, b) => a.rank - b.rank);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  async getOrganizing() {
    return await http
      .get(
        "/comittees?filters[type][$eq]=5&fields[0]=title&fields[1]=firstname&fields[2]=lastname&fields[3]=position1&fields[4]=position2&fields[5]=type&fields[6]=note"
      )
      .then(async (response) => {
        const data = await Promise.all(
          response.data.data.map((item) => CommiteeModel.get(item))
        );

        return data.sort((a, b) => a.rank - b.rank);
      })
      .catch((error) => {
        console.log(error);
      });
  }
})();
