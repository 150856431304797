import http from "./http-common";
import PartnersModel from "../models/partners";

export default new (class PartnersService {
  async getPartners(type) {
    return await http
      .get(
        `/partners?filters[type][$eq]=${type}&fields[0]=name&fields[1]=type&populate=*`
      )
      .then(async (response) => {
        const data = await Promise.all(
          response.data.data.map((item) => PartnersModel.get(item))
        );

        return data;
      })
      .catch((error) => {
        console.log(error);
      });
  }
})();
