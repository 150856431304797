import React, { useEffect, useState } from "react";
import download from "../../assets/images/about/download.png";
import ComingSoon from "../../components/ComingSoon";
import DownloadService from "../../services/about/download";

export default function Download() {
  useEffect(() => {
    window.scrollTo(0, 0);
    getDownloadData();
  }, []);

  let [downloadData, setDownloadData] = useState([]);

  const active = true;

  const getDownloadData = async () => {
    try {
      let res = await DownloadService.getDownload();
      setDownloadData(res);
    } catch {
      console.error();
    }
  };

  return (
    <div id="download" className="page-padding">
      {!active ? (
        <ComingSoon title="download" />
      ) : (
        <div>
          <div className="bg-darkGray section">
            <div className="page-container">
              <div className="flex items-center justify-center lg:justify-between py-[70px]">
                <h1 className="text-[32px] sm:text-[40px] md:text-[96px] text-white font-bold leading-[116px] text-center lg:text-left">
                  DOWNLOAD
                </h1>
                <img
                  className="hidden lg:block w-[310px]"
                  src={download}
                  alt=""
                />
              </div>
            </div>
          </div>

          <div className="section">
            <div className="page-container overflow-hidden">
              <div className="overflow-x-auto">
                <table className="w-full my-[53px]">
                  <tbody>
                    <tr className="uppercase bg-darkRed text-white text-[18px] lg:text-[24px] font-bold border-darkRed border-[1px] px-[26px] h-[100px]">
                      <th className="w-fit lg:w-[20%]">File name</th>
                      <th className="uppercase w-fit lg:w-[20%] border-white border-x-[1px]">
                        Type
                      </th>
                      <th className="uppercase w-fit lg:w-[20%] border-white border-x-[1px]">
                        Size
                      </th>
                      <th className="uppercase w-fit lg:w-[20%] border-white border-x-[1px]"></th>
                    </tr>
                    {downloadData &&
                      downloadData.map((item, i) => (
                        <tr
                          key={i}
                          className="text-[20px] text-darkGray font-bold border-darkRed border-[1px] h-[100px]"
                        >
                          <td className="text-left p-[20px]">
                            {item.filename}
                          </td>
                          <td className="text-center p-[20px] uppercase">
                            {item.file.data.attributes.ext}
                          </td>
                          <td className="text-cetner p-[20px]">
                            {item.file.data.attributes.size} KB
                          </td>
                          <td
                            className="text-center underline uppercase text-darkRed cursor-pointer text-left p-[20px]"
                            onClick={() => {
                              window.open(
                                `${process.env.REACT_APP_API_URL}${item.file.data.attributes.url}`,
                                "_self"
                              );
                            }}
                          >
                            Download
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
