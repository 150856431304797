import React, { useState, useEffect } from "react";
import { slide as Menu } from "react-burger-menu";
import HamburgerIcon from "hamburger-react";
import NavbarService from "../services/navbar";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import "../assets/css/hamburger.css";

export default function Hamburger(props) {
  const [isOpen, setOpen] = useState(false);
  let [navbarData, setNavbarData] = useState([]);

  useEffect(() => {
    getNavbarData();
  }, []);


  const getNavbarData = async () => {
    try {
      let res = await NavbarService.getNavbar();
      setNavbarData(res);
    } catch {
      console.error();
    }
  };

  var isMenuOpen = function (state) {
    setOpen(state.isOpen);
  };

  const openDropdown = (i) => {
    const temp = navbarData.slice();

    const result = temp.map((e) => {
      if (e.id === i + 1) {
        return { ...e, active: !temp[i].active };
      } else {
        return e;
      }
    });

    setNavbarData(result);
  };

  // const closeDropdown = () => {
  //   const temp = navbarData.slice();

  //   for (var i = 0; i < temp.length; i++) {
  //     temp[i].active = false;
  //   }

  //   setNavbarData(temp);
  // };

  return (
    <Menu
      id="hamburger-menu"
      onStateChange={isMenuOpen}
      right
      customBurgerIcon={<HamburgerIcon toggled={isOpen} />}
    >
      <div className="!flex flex-col gap-[21px]">
        {navbarData &&
          navbarData.map((item, i) => (
            <span key={i} className="flex flex-col">
              <div
                className="flex gap-[5px] items-center"
                onClick={() => openDropdown(i)}
              >
                <Link
                  className={
                    item.label.toLowerCase() === "about"
                      ? `pointer-events-none `
                      : undefined
                  }
                  to={
                    item.label.toLowerCase() === "home"
                      ? "/"
                      : item.label.toLowerCase() === "about"
                      ? ""
                      : `/${item.label.toLowerCase()}`
                  }
                >
                  <h1
                    id="home"
                    className="cursor-pointer menu-item font-normal text-[20px] text-black uppercase text-left"
                    href="/"
                  >
                    {item.label}
                  </h1>
                </Link>
                {item.sub_menu.length !== 0 && (
                  <FontAwesomeIcon
                    icon={faChevronLeft}
                    className={
                      "transition duration-200 h-[14px] w-[14px] cursor-pointer text-black " +
                      (item.active ? "-rotate-90" : null)
                    }
                  />
                  
                )}
              </div>

              {item.active && item.sub_menu.length !== 0 && (
                <ul className="mt-[15px] ml-[15px] list-disc flex flex-col gap-[12px]">
                  {item.sub_menu.map((item, i) => (
                    <Link key={i} to={`${item.href}`}>
                      <li className="text-left text-[16px] cursor-pointer capitalize whitespace-nowrap text-black">
                        {item.label}
                      </li>
                    </Link>
                  ))}
                </ul>
              )}
            </span>
          ))}
      </div>
    </Menu>
  );
}
