import http from "./http-common";
import ProgrammeModel from "../models/programme";
import SpeakerModel from "../models/speaker";

export default new (class ProgrammeService {
  async getProgramme() {
    return await http
      .get("/events?&fields[0]=start_date&populate=deep")
      .then(async (response) => {
        const data = await Promise.all(
          response.data.data.map((item) => ProgrammeModel.get(item))
        );

        const sortedData = data.sort((a, b) => {
          return new Date(a.start_date) - new Date(b.start_date);
        });

        return sortedData;
      })
      .catch((error) => {
        console.log(error);
      });
  }

  async getSpeaker() {
    return await http
      .get("/speakers?fields[0]=session_name&populate=deep")
      .then(async (response) => {
        const data = await Promise.all(
          response.data.data.map((item) => SpeakerModel.get(item))
        );

        return data;
      })
      .catch((error) => {
        console.log(error);
      });
  }
})();
