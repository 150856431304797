export default new (class DownloadModel {
  // static API_URL = process.env.REACT_APP_API_URL;

  async get(data) {
    const model = {};

    model.id = data.id || 0;
    model.filename = data.attributes.filename || "";
    model.file = data.attributes.file || null;

    return model;
  }
})();
