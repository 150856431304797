export default new (class ContactModel {
  // static API_URL = process.env.REACT_APP_API_URL;

  async get(data) {
    const model = {};

    model.id = data.id || 0;
    model.host_name = data.attributes.host_name || "";
    model.phone = data.attributes.host_phone_number || "";
    model.address = data.attributes.host_address || "";
    model.fb_link = data.attributes.host_url || "";
    model.image = data.attributes.host_image.data[0].attributes.url || "";

    return model;
  }
})();
