export default new (class PartnersModel {
  // static API_URL = process.env.REACT_APP_API_URL;

  async get(data) {
    const model = {};

    model.id = data.id || 0;
    model.name = data.attributes.name || "";
    model.type = data.attributes.type || null;
    model.image = data.attributes.image.data[0].attributes.url || "";

    return model;
  }
})();
