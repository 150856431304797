import React, { useEffect, useState } from "react";
import programmePic from "../../assets/images/programme/programme.png";
import PrimaryButton from "../../components/button/PrimaryButton";
import ComingSoon from "../../components/ComingSoon";
import ProgrammeService from "../../services/programme";

import programmeImg from "../../assets/images/programme/image-15.JPG";
// import programmeFile from "../../assets/images/programme/APSS-Program-uncompress.pdf";

// import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

// import { Pagination } from "swiper";

export default function Programme() {
  const active = true;

  // let [programmeData, setProgrammeData] = useState(null);
  let [speakerData, setSpeakerData] = useState(null);
  // let [selectedDate, setSelectedDate] = useState();

  useEffect(() => {
    window.scrollTo(0, 0);
    // getProgrammeData();
    getSpeakerData();
  }, []);

  // const getProgrammeData = async () => {
  //   try {
  //     let res = await ProgrammeService.getProgramme();
  //     setProgrammeData(res);

  //     setSelectedDate(res[0].start_date);
  //   } catch {
  //     console.error();
  //   }
  // };

  const getSpeakerData = async () => {
    try {
      let res = await ProgrammeService.getSpeaker();
      setSpeakerData(res);
    } catch {
      console.error();
    }
  };

  function dateCard(item) {
    return (
      <div>
        {item.session &&
          item.session.map((items, i) => {
            return (
              <div key={i}>
                {items.name ? (
                  <p className="py-[20px] text-left text-[24px] font-bold text-white uppercase">
                    {items.name}
                  </p>
                ) : (
                  <p className="py-[20px] text-[24px] pointer-events-none opacity-0">
                    ""
                  </p>
                )}
                <div
                  className={`bg-white ` + (i === 0 ? "min-h-[370px]" : null)}
                >
                  {items.activity &&
                    items.activity.map((detail, i) => {
                      if (detail.note === "break")
                        return (
                          <div
                            key={i}
                            className="text-center bg-darkGray2 py-[25px] px-[35px]"
                          >
                            <p className="text-[20px] text-realWhite font-bold">
                              {detail.head}
                            </p>
                            <p className="text-[20px] whitespace-pre-wrap">
                              {detail.detail}
                            </p>
                          </div>
                        );
                      else
                        return (
                          <div
                            key={i}
                            className="h-full text-left bg-white py-[25px] px-[35px]"
                          >
                            <p className="text-[20px] font-bold whitespace-pre-wrap">
                              {detail.head}
                            </p>
                            <p className="text-[20px] whitespace-pre-wrap">
                              {detail.detail}
                            </p>
                          </div>
                        );
                    })}
                </div>
              </div>
            );
          })}
      </div>
    );
  }

  function speakerCard(detail) {
    return (
      <div className="p-[40px] flex flex-col gap-[10px] lg:gap-[30px] justify-between items-center">
        {detail.image && detail.image.data ? (
          <img
            className="rounded-full w-[200px] h-[200px] object-cover"
            src={`${process.env.REACT_APP_API_URL}${detail.image.data.attributes.url}`}
            alt=""
          />
        ) : (
          <div className="rounded-full w-[200px] h-[200px] object-cover bg-gray" />
        )}
        <p className="text-[20px] font-bold whitespace-pre-wrap">
          {detail.title && <span>{detail.title}. </span>}
          <span>{detail.firstname} </span>
          <span>{detail.lastname}</span>
        </p>
        <p className="text-[18px] whitespace-pre-wrap">{detail.position}</p>
      </div>
    );
  }

  function getDay(inputDate) {
    const date = new Date(inputDate);
    const days = [
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
    ];
    const dayName = days[date.getUTCDay()];
    return dayName;
  }

  let previousDate = "";

  function getDateTime(date) {
    var getDate = new Date(date);

    const result = getDate.toLocaleDateString("en-GB", {
      day: "numeric",
      month: "long",
    });

    return String(result);
  }

  // const handleDownload = () => {
  //   const link = document.createElement("a");
  //   link.href = programmeFile;
  //   link.download = "APSS-programme.pdf";
  //   link.click();
  // };

  const handleOpenLink = () => {
    const googleSheetsLink =
      "https://docs.google.com/spreadsheets/d/19MSJrE0HSduR_5CJMONrDwwYKXP6F9sYfHXxT5An2AE/edit?fbclid=IwAR1q9JN0FHQO0k_3xyei0Xx050VEAUGup_sRk4TLyyKvEpXiQQldDnnF5_g#gid=40075113";

    window.open(googleSheetsLink, "_blank");
  };

  return (
    <div id="programme" className="page-padding">
      {!active ? (
        <ComingSoon title="programme" />
      ) : (
        <div>
          <div className="bg-darkGray section">
            <div className="page-container">
              <div className="flex items-center justify-center lg:justify-between py-[28px]">
                <h1 className="text-[32px] sm:text-[40px] md:text-[96px] text-white font-bold leading-[116px] text-center lg:text-left">
                  PROGRAMME
                </h1>
                <img
                  className="hidden lg:block w-[300px] xl:w-[370px]"
                  src={programmePic}
                  alt=""
                />
              </div>
            </div>
          </div>
          {/* <div className="hidden lg:block">
            <div className="section py-[68px]">
              <div className="page-container">
                <h1 className="uppercase text-[32px] sm:text-[40px] text-darkGray font-bold">
                  Important Dates
                </h1>

                <div className="mt-[30px] flex justify-around px-[10px]">
                  {programmeData &&
                    programmeData.map((item, i) => {
                      if (item.start_date !== previousDate) {
                        previousDate = item.start_date;
                        return (
                          <div key={i}>
                            <h1 className="uppercase text-[32px] font-bold text-darkGray">
                              {getDay(item.start_date)}
                            </h1>
                            <h1 className="font-normal text-[24px] text-center mt-[5px] uppercase">
                              {getDateTime(item.start_date)}
                            </h1>
                          </div>
                        );
                      }
                      return null;
                    })}
                </div>
              </div>
            </div>

            <div className="bg-darkGray section py-[50px]">
              <div className="page-container">
                <div className="grid grid-cols-4 gap-[20px] justify-center">
                  {programmeData &&
                    programmeData.map((item, i) => {
                      if (item.start_date !== previousDate) {
                        previousDate = item.start_date;
                        return (
                          <div key={i} className="flex gap-[20px]">
                            {i !== 0 ? (
                              <div className="min-h-[350px] border-[1px] border-gray" />
                            ) : null}
                            <div key={i} className="flex gap-[20px]">
                              <div className="w-full">{dateCard(item)}</div>
                            </div>
                          </div>
                        );
                      }
                      return (
                        <div key={i} className="flex gap-[20px]">
                          <div className="w-full">{dateCard(item)}</div>
                        </div>
                      );
                    })}
                </div>
              </div>
            </div>
          </div>

          <div className="lg:hidden block">
            <div className="section py-[68px]">
              <div className="page-container">
                <h1 className="uppercase text-[32px] sm:text-[40px] text-darkGray font-bold">
                  Important Dates
                </h1>

                <div className="mt-[30px] flex justify-around flex-wrap items-center gap-[15px] px-[10px]">
                  {programmeData &&
                    programmeData.map((item, i) => {
                      if (item.start_date !== previousDate) {
                        previousDate = item.start_date;
                        return (
                          <div
                            key={i}
                            onClick={() => setSelectedDate(item.start_date)}
                          >
                            <h1
                              className={
                                `uppercase text-[20px] md:text-[32px] font-semibold text-darkGray ` +
                                (item.start_date === selectedDate
                                  ? "!font-bold text-darkRed"
                                  : null)
                              }
                            >
                              {getDay(item.start_date)}
                            </h1>
                            <h1
                              className={
                                `font-normal text-[14px] md:text-[24px] text-center mt-[5px] uppercase ` +
                                (item.start_date === selectedDate
                                  ? "underline !font-bold text-darkRed"
                                  : null)
                              }
                            >
                              {getDateTime(item.start_date)}
                            </h1>
                          </div>
                        );
                      }
                      return null;
                    })}
                </div>
              </div>
            </div>

            <div className="bg-darkGray section py-[30px]">
              <div>
                <Swiper
                  spaceBetween={13}
                  slidesPerView={1.7}
                  centeredSlides={true}
                  // pagination={true}
                  // modules={[Pagination]}
                  scrollbar={{ draggable: true }}
                >
                  {programmeData &&
                    programmeData
                      .filter((event) => event.start_date === selectedDate)
                      .map((item, i) => (
                        <SwiperSlide key={i} className="!p-[0px]">
                          <div key={i} className="flex gap-[20px]">
                            <div className="w-full">{dateCard(item)}</div>
                          </div>
                        </SwiperSlide>
                      ))}
                </Swiper>
              </div>
            </div>
          </div> */}

          {/* New Update */}
          <div className="section py-[38px]">
            <div className="page-container">
              <h1 className="uppercase text-[32px] sm:text-[40px] text-darkGray font-bold">
                Important Dates
              </h1>
              <img
                className="mt-[35px] w-full cursor-pointer"
                onClick={() => handleOpenLink()}
                src={programmeImg}
                alt=""
              />
            </div>
          </div>

          <div className="py-[40px] section bg-white">
            <div className="page-container section">
              {/* <PrimaryButton
                text="download full version programme"
                function={() => handleDownload()}
              /> */}
              <PrimaryButton
                text="view full version programme"
                function={() => handleOpenLink()}
              />
            </div>
          </div>
          <div className="bg-darkGray section py-[130px]">
            <div className="page-container">
              <div className="flex flex-col gap-[60px]">
                {speakerData &&
                  speakerData.map((item, i) => (
                    <div key={i}>
                      <h1 className="text-white text-[32px] sm:text-[40px] font-bold uppercase">
                        {item.session_name}
                      </h1>
                      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-[20px] mt-[45px]">
                        {item.speaker.map((data, i) => (
                          <div className="bg-white" key={i}>
                            {speakerCard(data)}
                          </div>
                        ))}
                      </div>
                    </div>
                  ))}
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
