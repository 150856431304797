export default new (class NavbarModel {
  // static API_URL = process.env.REACT_APP_API_URL;

  async get(data) {
    const model = {};

    model.id = data.id || 0;
    model.label = data.attributes.label || "";
    model.link = data.attributes.href || "";
    model.sub_menu = data.attributes.sub_menu || [];
    model.active = false;

    return model;
  }
})();
