import http from "./http-common";
import NavbarModel from "../models/navbar";

// Navbar APIs
export default new (class NavbarService {
  // Get all members
  async getNavbar() {
    return await http
      .get("/template-navbars?fields%5B0%5D=label&fields%5B1%5D=href&populate=*")
      .then(async (response) => {
        const data = await Promise.all(
          response.data.data.map((navbar) => NavbarModel.get(navbar))
        );

        return data;
      })
      .catch((error) => {
        console.log(error);
      });
  }
})();
