import http from "./http-common";
import FooterModel from "../models/footer";

// Navbar APIs
export default new (class FooterService {
  // Get all members
  async getFooter() {
    return await http
      .get("/footer?populate=deep")
      .then(async (response) => {
        const data = await FooterModel.get(response.data.data);

        return data;
      })
      .catch((error) => {
        console.log(error);
      });
  }
})();
