import React, { useEffect, useState } from "react";
import SecondaryButton from "../../components/button/SecondaryButton";
import landing from "../../assets/images/home/new-landing.png";
import girl from "../../assets/images/home/team.png";
// import stair from "../../assets/images/home/stair.png";
import man from "../../assets/images/home/man.png";
// import PrimaryButton from "../../components/button/PrimaryButton";
import HomeService from "../../services/home";
import HostService from "../../services/about/host";
import getTime from "../../functions/getTime";
import background from "../../assets/images/home/main-bg.png";
import sistam from "../../assets/images/home/sistam-oc.jpg";

export default function Home() {
  useEffect(() => {
    window.scrollTo(0, 0);
    getConferenceData();
    getPaperTopicData();
    getImportantDateData();
    getConferenceFiles();
    getHostData();
    getCoHostData();
    getFullTemplateFiles();
  }, []);

  let [conferenceData, setConferenceData] = useState(null);
  let [paperData, setPaperData] = useState(null);
  let [importantDateData, setImportantDateData] = useState(null);
  let [conferenceFiles, setConferenceFiles] = useState(null);
  let [fullTemplateFiles, setFullTemplateFiles] = useState(null);

  const getConferenceFiles = async () => {
    try {
      let res = await HomeService.getConferenceFiles();
      setConferenceFiles(res);
    } catch {
      console.error();
    }
  };

  const getFullTemplateFiles = async () => {
    try {
      let res = await HomeService.getFullTemplateFiles();
      setFullTemplateFiles(res);
    } catch {
      console.error();
    }
  };

  const getConferenceData = async () => {
    try {
      let res = await HomeService.getConferenceDetail();
      setConferenceData(res);
    } catch {
      console.error();
    }
  };

  const getPaperTopicData = async () => {
    try {
      let res = await HomeService.getPaperTopic();
      setPaperData(res);
    } catch {
      console.error();
    }
  };

  const getImportantDateData = async () => {
    try {
      let res = await HomeService.getImportantDate();
      setImportantDateData(res);
    } catch {
      console.error();
    }
  };

  let [hostData, setHostData] = useState([]);
  let [coHostData, setCoHostData] = useState([]);

  const getHostData = async () => {
    try {
      let res = await HostService.getHost();
      setHostData(res);
    } catch {
      console.error();
    }
  };

  const getCoHostData = async () => {
    try {
      let res = await HostService.getCoHost();
      setCoHostData(res);
    } catch {
      console.error();
    }
  };

  return (
    <div id="mainpage" className="page-padding">
      <div
        className="bg-darkGray section object-cover !bg-cover"
        style={{ backgroundImage: `url(${background})` }}
      >
        <div className="page-container">
          <div className="flex flex-col xl:flex-row gap-[30px] xl:gap-[80px] py-[40px] xl:py-[150px] items-center">
            {conferenceData && (
              <div>
                <h1 className="text-center text-[96px] text-white font-bold leading-[116px] text-left">
                  {conferenceData.abbr}
                </h1>
                <p className="text-center uppercase text-[32px] text-white font-bold leading-[32px] text-left">
                  {conferenceData.name}
                </p>
                <p className="text-center text-[16px] text-white font-medium py-[30px] text-justify">
                  {conferenceData.detail}
                </p>
                <div className="section">
                  <div className="hidden xl:block">
                    <SecondaryButton
                      text="register now"
                      function={() => {
                        window.open(`/registration`, "_self");
                      }}
                    />
                  </div>
                </div>
              </div>
            )}
            <img className="w-[460px] h-fit" src={landing} alt="" />
            <div className="block xl:hidden">
              <SecondaryButton
                text="register now"
                function={() => {
                  window.open(`/registration`, "_self");
                }}
              />
            </div>
          </div>
        </div>
      </div>

      <div className="section py-[63px]">
        <div className="page-container section">
          <div className="flex flex-wrap lg:grid lg:grid-cols-4 gap-[10px] sm:gap-[15px] md:gap-[70px]">
            {hostData && (
              <div className="w-[120px] h-[120px] sm:w-[200px] sm:h-[200px] rounded-full bg-realWhite p-[30px] section">
                <img
                  onClick={() => {
                    if (hostData && hostData.url)
                      // eslint-disable-next-line
                      window.open(
                        // eslint-disable-next-line
                        `${hostData.url.replace(/[^\x00-\x7F]/g, "")}`,
                        "_blank"
                      );
                  }}
                  className="w-full"
                  src={`${process.env.REACT_APP_API_URL}${hostData.image}`}
                  alt=""
                />
              </div>
            )}
            {hostData && (
              <div className="w-[120px] h-[120px] sm:w-[200px] sm:h-[200px] rounded-full bg-realWhite p-[30px] section">
                <img
                  onClick={() => {
                    if (hostData && hostData.url)
                      // eslint-disable-next-line
                      window.open(
                        // eslint-disable-next-line
                        `${hostData.url.replace(/[^\x00-\x7F]/g, "")}`,
                        "_blank"
                      );
                  }}
                  className="w-full"
                  src={`${process.env.REACT_APP_API_URL}${hostData.image2}`}
                  alt=""
                />
              </div>
            )}
            {coHostData &&
              coHostData.map((item, i) => (
                <div
                  key={i}
                  className="w-[120px] h-[120px] sm:w-[200px] sm:h-[200px] rounded-full bg-realWhite p-[30px] section"
                >
                  <img
                    onClick={() => {
                      if (item && item.url)
                        // eslint-disable-next-line
                        window.open(
                          // eslint-disable-next-line
                          `${item.url.replace(/[^\x00-\x7F]/g, "")}`,
                          "_blank"
                        );
                    }}
                    className="w-full"
                    src={`${process.env.REACT_APP_API_URL}${item.image}`}
                    alt=""
                  />
                </div>
              ))}
          </div>
        </div>
      </div>

      <div className="bg-darkGray section">
        <div className="page-container">
          <div className="py-[90px]">
            <h1 className="text-[32px] sm:text-[40px] text-center xl:text-left font-bold text-white">
              CALL FOR PAPERS
            </h1>
            <div className="grid grid-cols-1 xl:grid-cols-2 gap-[20px] mt-[50px] items-start">
              <div className="section flex-col">
                <div className="w-[90%] sm:w-[70%] lg:w-[50%] xl:w-full bg-white section">
                  <ul className="hidden xl:flex w-[60%] ml-[30px] list-disc flex-col gap-[20px] py-[65px]">
                    {paperData &&
                      paperData.slice(0, 7).map((item, i) => (
                        <li
                          key={i}
                          className="text-left font-semibold text-[20px] text-darkGray"
                        >
                          {item.name}
                        </li>
                      ))}
                  </ul>

                  <ul className="flex xl:hidden w-[60%] ml-[30px] list-disc flex-col gap-[20px] py-[65px]">
                    {paperData &&
                      paperData.map((item, i) => (
                        <li
                          key={i}
                          className="text-left font-semibold text-[20px] text-darkGray"
                        >
                          {item.name}
                        </li>
                      ))}
                  </ul>
                </div>
                <p className="text-[20px] text-white font-bold mt-[45px] mb-[20px] text-center">
                  The abstract submission will be available on <br />
                  15 January 2023.
                </p>
                {conferenceFiles && (
                  <SecondaryButton
                    underline={true}
                    text="abstract template"
                    function={() => {
                      window.open(
                        `${process.env.REACT_APP_API_URL}${conferenceFiles.attributes.file.data[0].attributes.url}`,
                        "_self"
                      );
                    }}
                  />
                )}
              </div>
              <div className="section flex-col">
                <div className="w-full bg-white section xl:!flex !hidden">
                  <ul className="w-[60%] ml-[30px] list-disc flex flex-col gap-[20px] py-[65px]">
                    {paperData &&
                      paperData.slice(7, paperData.length).map((item, i) => (
                        <li
                          key={i}
                          className="text-left font-semibold text-[20px] text-darkGray"
                        >
                          {item.name}
                        </li>
                      ))}
                  </ul>
                </div>
                <p className="text-[20px] text-white font-bold mt-[45px] mb-[20px] text-center">
                  After the abstract acceptance, The author will be <br />
                  requested to submit the full manuscript
                </p>
                {fullTemplateFiles && (
                  <SecondaryButton
                    underline={true}
                    text="full paper template"
                    function={() => {
                      window.open(
                        `${process.env.REACT_APP_API_URL}${fullTemplateFiles?.attributes?.file?.data?.attributes.url}`,
                        "_self"
                      );
                    }}
                  />
                )}
                {/* <p className="text-[20px] text-white font-bold mt-[10px] text-center">
                  Coming soon
                </p> */}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="section py-[63px]">
        <div className="page-container">
          <div className="grid grid-cols-1 xl:grid-cols-5 gap-[20px] w-full">
            <div className="bg-lightBrown py-[60px] px-[20px] xl:px-[85px] col-spans-1 xl:col-span-3">
              <h1 className="text-[32px] sm:text-[40px] text-left font-bold text-darkGray">
                IMPORTANT DATES
              </h1>

              <ul className="flex flex-col gap-[12px] mt-[25px]">
                {importantDateData &&
                  importantDateData.map((item, i) => (
                    <div key={i}>
                      <div className="flex items-top gap-[15px]">
                        <li
                          className={
                            `text-left text-[20px] text-darkGray ` +
                            (item.status === "canceled" && `line-through`)
                          }
                        >
                          <span>
                            {getTime(item.start_date, item.end_date, true)}
                          </span>
                          : {item.detail}
                        </li>
                        {item.status === "updated" && (
                          <div className="h-fit text-[18px] text-white font-semibold bg-darkRed pt-[3px] px-[10px]">
                            UPDATE
                          </div>
                        )}
                      </div>
                      <ul className="ml-[30px] mt-[15px] list-disc flex flex-col gap-[16px]">
                        {item.sub_date.map((item, i) => (
                          <li
                            key={i}
                            className="text-left text-[20px] text-darkGray"
                          >
                            <span>
                              {getTime(item.start_date, item.end_date)}
                            </span>
                            : {item.detail}
                          </li>
                        ))}
                      </ul>
                    </div>
                  ))}
              </ul>
            </div>
            <div className="py-[35px] xl:py-0 bg-darkRed section w-full col-span-1 xl:col-span-2">
              <img className="w-[390px]" src={girl} alt="" />
            </div>
          </div>
        </div>
      </div>

      <div className="bg-darkGray section py-[95px]">
        <div className="page-container z-10">
          <div className="xl:grid flex flex-col xl:grid-cols-2 gap-[20px] w-full">
            <div className="section w-full col-span-1 !object-cover">
              {/* <img
                className="w-full h-full object-contain"
                src={stair}
                alt=""
              /> */}
              <img
                className="w-full h-full object-contain"
                src={sistam}
                alt=""
              />
            </div>
            <div className="w-full col-span-1 py-[60px] xl:py-[30px] px-[25px] xl:px-[80px] bg-white flex flex-col justify-center">
              <h1 className="text-center xl:text-left font-bold text-[32px] sm:text-[40px] text-darkGray">
                SISTAM
              </h1>
              <p className="text-center xl:text-left font-bold text-[20px] text-darkGray py-[25px]">
                Joint Event with Smart Industrial Safety and Technology for
                Advanced Maintenance (SISTAM)
              </p>

              <a
                href="https://sistam-asia.com/"
                target="_blank"
                rel="noopener noreferrer"
                className="text-[18px] text-blue underline text-center cursor-pointer"
              >
                https://sistam-asia.com/
              </a>
              {/* <div className="flex justify-center xl:justify-start items-center">
                <PrimaryButton
                  text="join now"
                  underline={true}
                  function={() => {
                    window.open(`http://sistam.org/`, "_blank");
                  }}
                />
              </div> */}
            </div>
          </div>
        </div>
      </div>

      <div className="section py-[80px] xl:pb-[0px] xl:pt-[50px] relative">
        <div className="page-container z-10">
          <div className="xl:grid xl:grid-cols-7 gap-[56px] w-full">
            <div className="w-full col-span-1 xl:col-span-5 py-[60px] px-[20px] xl:px-[80px] bg-darkRed h-fit mt-[30px] flex flex-col gap-[30px]">
              <div>
                <h1 className="text-center xl:text-left font-bold text-[32px] sm:text-[40px] text-white">
                  JOURNAL ALERT
                </h1>
                <p className="text-center xl:text-left font-bold text-[20px] text-white py-[30px]">
                  The selected article will be invited to contribute to the
                  special issue of
                </p>
              </div>
              {/* <div className="flex flex-col md:flex-row justify-between items-center gap-[45px]">
                <p className="text-center xl:text-left font-bold text-[20px] text-white">
                  “Safety Science” (Elsevier, Impact Factor 6.392)
                </p>
                <SecondaryButton
                  text="read more"
                  underline={true}
                  function={() => {
                    window.open(
                      `https://www.sciencedirect.com/journal/safety-science`,
                      "_blank"
                    );
                  }}
                />
              </div> */}
              <div className="flex flex-col md:flex-row justify-between items-center gap-[45px]">
                <p className="text-center xl:text-left font-bold text-[20px] text-white">
                  “Journal of Loss Prevention in the Process Industries”
                  (Elsevier, Impact Factor 3.916)
                </p>
                <SecondaryButton
                  text="read more"
                  underline={true}
                  function={() => {
                    window.open(
                      `https://www.sciencedirect.com/journal/journal-of-loss-prevention-in-the-process-industries`,
                      "_blank"
                    );
                  }}
                />
              </div>
              <div className="flex flex-col md:flex-row justify-between items-center gap-[45px]">
                <p className="text-center xl:text-left font-bold text-[20px] text-white">
                  “KMUTT Research and Development Journal” (TCI tier 1).
                </p>
                <SecondaryButton
                  text="read more"
                  underline={true}
                  function={() => {
                    window.open(`https://journal.kmutt.ac.th/`, "_blank");
                  }}
                />
              </div>
            </div>
            <div className="!hidden xl:!block xl:relative section w-full col-span-1 xl:col-span-2">
              <img className="w-[200px] xl:w-[390px]" src={man} alt="" />
            </div>
          </div>
        </div>
        <div className="hidden md:!block z-0 xl:!hidden right-[10px] absolute">
          <img className="w-[320px]" src={man} alt="" />
        </div>
      </div>
    </div>
  );
}
