import "./App.css";
import React from "react";
import { BrowserRouter, Redirect, Route, Switch } from "react-router-dom";
import "./assets/css/page.css";

import Home from "./pages/home/Home.js";
import Navbar from "./components/Navbar";
import Footer from "./components/Footer";
import Venue from "./pages/about/Venue.js";
import Host from "./pages/about/Host";
import Committees from "./pages/about/Committees";
import ContactUs from "./pages/about/ContactUs";
import Programme from "./pages/programme/Programme";
import Sponsors from "./pages/sponsors/Sponsors";
import Registration from "./pages/registration/Registration";
import Submission from "./pages/submission/Submission";
import Hamburger from "./components/Hamburger";
import Privacy from "./pages/about/Privacy";
import Download from "./pages/about/Download";

function App() {
  return (
    <BrowserRouter>
      <Switch>
        <Route exact path="/">
          <div className="App">
            <Navbar />
            <Hamburger />
            <Home />
            <Footer />
          </div>
        </Route>

        <Route path="/venue">
          <div className="App">
            <Navbar />
            <Hamburger />
            <Venue />
            <Footer />
          </div>
        </Route>

        <Route path="/hosts">
          <div className="App">
            <Navbar />
            <Hamburger />
            <Host />
            <Footer />
          </div>
        </Route>

        <Route path="/comittees">
          <div className="App">
            <Navbar />
            <Hamburger />
            <Committees />
            <Footer />
          </div>
        </Route>

        <Route path="/download">
          <div className="App">
            <Navbar />
            <Hamburger />
            <Download />
            <Footer />
          </div>
        </Route>

        <Route path="/contact-us">
          <div className="App">
            <Navbar />
            <Hamburger />
            <ContactUs />
            <Footer />
          </div>
        </Route>

        <Route path="/programme">
          <div className="App">
            <Navbar />
            <Hamburger />
            <Programme />
            <Footer />
          </div>
        </Route>

        <Route path="/submission">
          <div className="App">
            <Navbar />
            <Hamburger />
            <Submission />
            <Footer />
          </div>
        </Route>

        <Route path="/registration">
          <div className="App">
            <Navbar />
            <Hamburger />
            <Registration />
            <Footer />
          </div>
        </Route>

        <Route path="/partners">
          <div className="App">
            <Navbar />
            <Hamburger />
            <Sponsors />
            <Footer />
          </div>
        </Route>

        <Route path="/privacy">
          <div className="App">
            <Navbar />
            <Hamburger />
            <Privacy />
            <Footer />
          </div>
        </Route>

        <Route exact path="*">
          <Redirect to="/" />
        </Route>
      </Switch>
    </BrowserRouter>
  );
}

export default App;
