function getTime(start_date, end_date, showYear) {
  var getStartDate = new Date(start_date);
  var getEndDate = new Date(end_date);

  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  var s_month = monthNames[getStartDate.getMonth()];
  var s_day = getStartDate.getUTCDate();
  var s_year = getStartDate.getUTCFullYear();

  if (!showYear) {
    s_year = "";
  }

  if (end_date) {
    var e_month = monthNames[getEndDate.getMonth()];
    var e_day = getEndDate.getUTCDate();
    var e_year = getEndDate.getUTCFullYear();

    if (!showYear) {
      e_year = "";
    }

    if (s_day === e_day && s_month === e_month && s_year === e_year) {
      return s_day + " " + s_month + " " + s_year;
    } else if (s_month === e_month && s_year === e_year) {
      return s_day + "-" + e_day + " " + s_month + " " + s_year;
    } else if (s_year === e_year) {
      return s_day + " " + s_month + "-" + e_day + " " + e_month + " " + s_year;
    } else {
      return (
        s_day +
        " " +
        s_month +
        " " +
        s_year +
        "-" +
        e_day +
        " " +
        e_month +
        " " +
        e_year
      );
    }
  }

  return s_day + " " + s_month + " " + s_year;
}

export default getTime;
