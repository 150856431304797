import React, { useEffect, useState } from "react";
import contact from "../../assets/images/about/contact.png";
// import logo from "../../assets/images/logo.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPhone,
  faLocationDot,
  faRefresh,
} from "@fortawesome/free-solid-svg-icons";
import { faFacebookF } from "@fortawesome/free-brands-svg-icons";
import PrimaryButton from "../../components/button/PrimaryButton";
import ContactService from "../../services/about/contact";
import validateFunction from "../../functions/validate";

const initialInputValues = {
  name: "",
  telephone_number: "",
  email: "",
  message: "",
};

export default function ContactUs() {
  const [code, setCode] = useState(randomCode());
  let [contactData, setContactData] = useState([]);
  const [userInput, setUserInput] = useState(initialInputValues);
  const [inputCode, setInputCode] = useState("");
  const [invalidMessage, setInvalidMessage] = useState(null);

  const getContactData = async () => {
    try {
      let res = await ContactService.getContact();
      setContactData(res);
    } catch {
      console.error();
    }
  };

  const handleUserInputChange = (field) => (e) => {
    let value = e.target.value;

    setUserInput({
      ...userInput,
      [field]: value,
    });
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    getContactData();
  }, []);

  function randomCode() {
    return Math.floor(100000 + Math.random() * 900000);
  }

  async function submitMessage() {
    if (userInput.name.length === 0) {
      setInvalidMessage("Name can't be empty");
      setCode(randomCode());
    }

    // else if (
    //   !userInput.telephone_number.match(/^[0-9]+$/) &&
    //   userInput.telephone_number.length !== 0
    // ) {
    //   setInvalidMessage("Invalid phone number");
    //   setCode(randomCode());
    // }
    else if (
      userInput.email.length === 0 ||
      !validateFunction.validateEmail(userInput.email)
    ) {
      setInvalidMessage("Invalid Email");
      setCode(randomCode());
    } else if (userInput.message.length === 0) {
      setInvalidMessage("Message can't be empty");
      setCode(randomCode());
    } else if (code.toString() !== inputCode) {
      setInvalidMessage("Code not match");
      setCode(randomCode());
    } else {
      ContactService.addContact(userInput);
      setUserInput(initialInputValues);
      setInputCode("");
      setInvalidMessage(null);
    }
  }

  return (
    <div id="contact-us" className="page-padding">
      <div className="bg-darkGray section">
        <div className="page-container">
          <div className="flex items-center justify-center lg:justify-between pb-[80px] lg:pb-[0px] pt-[38px] lg:pt-[46px]">
            <h1 className="text-[32px] sm:text-[40px] md:text-[96px] text-white font-bold leading-[116px] text-center lg:text-left">
              CONTACT US
            </h1>
            <img className="hidden lg:block w-[316px]" src={contact} alt="" />
          </div>
        </div>
      </div>

      <div className="section">
        <div className="page-container">
          <div className="py-[80px] grid grid-cols-1 lg:grid-cols-2 divide-y lg:divide-y-0 lg:divide-x gap-[60px]">
            <div className="flex-col section">
              {contactData && (
                <div className="section flex-col">
                  <img
                    className="w-[140px] md:w-[250px]"
                    src={`${process.env.REACT_APP_API_URL}${contactData.image}`}
                    alt=""
                  />
                  <p className="font-semibold text-[16px] md:text-[32px] mt-[20px]">
                    {contactData.host_name}
                  </p>
                </div>
              )}

              <div className="flex flex-col gap-[45px] mt-[60px]">
                <div>
                  <FontAwesomeIcon
                    icon={faPhone}
                    className="w-[50px] h-[50px] text-darkGray"
                  />
                  {contactData && (
                    <div>
                      <p className="font-semibold text-[24px] text-darkGray">
                        Tel
                      </p>
                      <p className="font-medium text-[16px]">
                        {contactData.phone}
                      </p>
                    </div>
                  )}
                </div>

                <div>
                  <FontAwesomeIcon
                    icon={faLocationDot}
                    className="w-[50px] h-[50px] text-darkGray"
                  />
                  {contactData && (
                    <div>
                      <p className="font-semibold text-[24px] text-darkGray">
                        Address
                      </p>
                      <p className="font-medium text-[16px]">
                        {contactData.address}
                      </p>
                    </div>
                  )}
                </div>

                <div
                  onClick={() => {
                    if (contactData)
                      window.open(`${contactData.fb_link}`, "_blank");
                  }}
                >
                  <FontAwesomeIcon
                    icon={faFacebookF}
                    className="w-[50px] h-[50px] text-darkGray"
                  />
                  <p className="font-semibold text-[24px] text-darkGray">
                    Facebook
                  </p>
                  <p className="font-medium text-[16px]">
                    Chemical Engineering, KMUTT
                  </p>
                </div>
              </div>
            </div>
            <div className="pl-[0px] pt-[30px] lg:pt-[0px] lg:pl-[60px] flex flex-col gap-[20px]">
              <div className="flex-col flex justify-start">
                <p className="font-bold text-[14px] sm:text-[20px] text-darkGray text-left">
                  Name*
                </p>
                <input
                  value={userInput.name}
                  onChange={handleUserInputChange("name")}
                  className="bg-realWhite text-[16px] text-darkGray p-[10px] mt-[7px]"
                  placeholder="Name"
                />
              </div>

              <div className="flex-col flex justify-start">
                <p className="font-bold text-[14px] sm:text-[20px] text-darkGray text-left">
                  Telephone
                </p>
                <input
                  value={userInput.telephone_number}
                  onChange={handleUserInputChange("telephone_number")}
                  type={"text"}
                  // maxLength="10"
                  className="bg-realWhite text-[16px] text-darkGray p-[10px] mt-[7px]"
                  placeholder="Telephone"
                />
              </div>

              <div className="flex-col flex justify-start">
                <p className="font-bold text-[14px] sm:text-[20px] text-darkGray text-left">
                  E-mail*
                </p>
                <input
                  value={userInput.email}
                  onChange={handleUserInputChange("email")}
                  className="bg-realWhite text-[16px] text-darkGray p-[10px] mt-[7px]"
                  placeholder="E-mail"
                />
              </div>

              <div className="flex-col flex justify-start">
                <p className="font-bold text-[14px] sm:text-[20px] text-darkGray text-left">
                  Message*
                </p>
                <textarea
                  value={userInput.message}
                  onChange={handleUserInputChange("message")}
                  rows="7"
                  className="bg-realWhite text-[16px] text-darkGray p-[10px] mt-[7px]"
                  placeholder="Message"
                />
              </div>

              <div className="flex justify-between items-center">
                <p className="font-bold text-[14px] sm:text-[20px] text-darkGray text-left">
                  Security code
                </p>
                <div className="bg-realWhite text-[20px] py-[8px] px-[25px] text-center font-bold w-[150px]">
                  {code}
                </div>
                <div
                  className="hidden md:block bg-darkRed text-[16px] py-[8px] px-[25px] font-bold text-white cursor-pointer"
                  onClick={() => {
                    setCode(randomCode());
                  }}
                >
                  GET NEW CODE
                </div>
                <FontAwesomeIcon
                  onClick={() => {
                    setCode(randomCode());
                  }}
                  icon={faRefresh}
                  className="block text-[20px] md:hidden bg-darkRed p-[10px] text-realWhite w-[21px]"
                />
              </div>

              <div className="flex-col flex justify-start">
                <p className="font-bold text-[14px] sm:text-[20px] text-darkGray text-left">
                  Enter security code*
                </p>
                <input
                  value={inputCode}
                  onChange={(e) => setInputCode(e.target.value)}
                  className="bg-realWhite text-[16px] text-darkGray p-[10px] mt-[7px]"
                  placeholder="Security Code"
                />
                <p className="text-[16px] text-darkGray text-left mt-[5px]">
                  (*required)
                </p>
                {invalidMessage && (
                  <p className="text-left text-[16px] text-darkRed font-bold">
                    {invalidMessage}
                  </p>
                )}
              </div>

              <div className="section">
                <PrimaryButton
                  text="submit message"
                  function={() => submitMessage()}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
