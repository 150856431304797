import React, { useEffect, useState } from "react";
import logo from "../assets/images/new-logo.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import PrimaryButton from "./button/PrimaryButton";
import { Link } from "react-router-dom";
import NavbarService from "../services/navbar";

export default function Navbar() {
  let [navbarData, setNavbarData] = useState([]);

  useEffect(() => {
    getNavbarData();
  }, []);

  const getNavbarData = async () => {
    try {
      let res = await NavbarService.getNavbar();
      setNavbarData(res);
    } catch {
      console.error();
    }
  };

  const openDropdown = (i) => {
    const temp = navbarData.slice();

    const result = temp.map((e) => {
      if (e.id === i + 1) {
        return { ...e, active: true };
      } else {
        return e;
      }
    });

    setNavbarData(result);
  };

  const closeDropdown = () => {
    const temp = navbarData.slice();

    for (var i = 0; i < temp.length; i++) {
      temp[i].active = false;
    }

    setNavbarData(temp);
  };

  function NavMenu(item, i) {
    return (
      <div
        className="relative cursor-pointer"
        onMouseOver={() => openDropdown(i)}
        onMouseLeave={() => closeDropdown()}
      >
        <div className="section gap-[8px]">
          <Link
            className={
              item.label.toLowerCase() === "about"
                ? `pointer-events-none `
                : undefined
            }
            to={
              item.label.toLowerCase() === "home"
                ? "/"
                : item.label.toLowerCase() === "about"
                ? ""
                : `/${item.label.toLowerCase()}`
            }
          >
            <h1
              className={
                "text-[16px] uppercase hover:underline cursor-pointer " +
                (item.active ? "underline" : null)
              }
            >
              {item.label}
            </h1>
          </Link>
          {item.sub_menu.length !== 0 && (
            <FontAwesomeIcon
              icon={faChevronLeft}
              className={
                "transition duration-200 h-[14px] w-[14px] cursor-pointer text-black " +
                (item.active ? "-rotate-90" : null)
              }
            />
          )}
        </div>
        {item.sub_menu.length !== 0 && (
          <div
            onMouseOver={() => openDropdown(i)}
            onMouseLeave={() => closeDropdown()}
            className={
              "transition-all duration-200 bg-white absolute p-[20px] " +
              (item.active
                ? "opacity-100"
                : "opacity-0 pointer-events-none select-none cursor-normal")
            }
          >
            <ul className="ml-[15px] list-disc flex flex-col gap-[12px]">
              {item.sub_menu.map((item, i) => (
                <Link key={i} to={`${item.href}`}>
                  <li className="text-left text-[16px] cursor-pointer capitalize whitespace-nowrap">
                    {item.label}
                  </li>
                </Link>
              ))}
            </ul>
          </div>
        )}
      </div>
    );
  }

  return (
    <div
      id="navbar"
      className="fixed section bg-white w-full !z-[99] shadow-lg"
    >
      <div className="navbar-container py-[22px] flex items-center justify-between">
        <Link to={`/`}>
          <img
            className="w-[150px] xl:w-[120px] cursor-pointer"
            src={logo}
            alt=""
          />
        </Link>

        <div className="flex items-center gap-[60px] desktop-navbar">
          {navbarData &&
            navbarData.map((item, i) => <div key={i}>{NavMenu(item, i)}</div>)}
        </div>
        <div className="desktop-navbar">
          <PrimaryButton
            text="register / login"
            function={() => {
              window.open(`/registration`, "_self");
            }}
          />
        </div>
      </div>
    </div>
  );
}
