import React, { useEffect, useState } from "react";
import committees from "../../assets/images/about/committes.png";
import ComingSoon from "../../components/ComingSoon";
import CommitteeService from "../../services/about/commitee";

export default function Committees() {
  useEffect(() => {
    window.scrollTo(0, 0);
    getJapanData();
    getKoreaData();
    getThaiData();
    getAcademicData();
    getOrganizing();
  }, []);

  let [japanData, setJapanData] = useState([]);
  let [koreaData, setKoreaData] = useState([]);
  let [thaiData, setThaiData] = useState([]);
  let [academicData, setAcademicData] = useState([]);
  let [organizingData, setOrganizingData] = useState([]);
  const showData = true;
  const active = true;

  const getJapanData = async () => {
    try {
      let res = await CommitteeService.getJapan();
      setJapanData(res);
    } catch {
      console.error();
    }
  };

  const getKoreaData = async () => {
    try {
      let res = await CommitteeService.getKorea();
      setKoreaData(res);
    } catch {
      console.error();
    }
  };

  const getThaiData = async () => {
    try {
      let res = await CommitteeService.getThai();
      setThaiData(res);
    } catch {
      console.error();
    }
  };

  const getAcademicData = async () => {
    try {
      let res = await CommitteeService.getAcademic();
      setAcademicData(res);
    } catch {
      console.error();
    }
  };

  const getOrganizing = async () => {
    try {
      let res = await CommitteeService.getOrganizing();
      setOrganizingData(res);
    } catch {
      console.error();
    }
  };

  return (
    <div id="venue" className="page-padding">
      {!active ? (
        <ComingSoon title="committees" />
      ) : (
        <div>
          <div className="bg-darkGray section">
            <div className="page-container">
              <div className="flex items-center justify-center lg:justify-between py-[70px]">
                <h1 className="text-[32px] sm:text-[40px] md:text-[96px] text-white font-bold leading-[116px] text-center lg:text-left">
                  COMMITTEES
                </h1>
                <img
                  className="hidden lg:block w-[310px]"
                  src={committees}
                  alt=""
                />
              </div>
            </div>
          </div>

          {showData ? (
            <div>
              <div className="section">
                <div className="page-container">
                  <div className="py-[70px] flex flex-col">
                    <h1 className="text-[32px] sm:text-[40px] text-darkGray font-bold text-left uppercase">
                      Executive Committee from Japan
                    </h1>

                    <table className="w-full mt-[30px]">
                      <tbody>
                        {japanData &&
                          japanData.map((item, i) => (
                            <tr key={i}>
                              <td
                                className={
                                  `text-left py-[15px] pr-[20px] align-top ` +
                                  (!item.position1 ? "w-[50%]" : "w-[20%]")
                                }
                              >
                                {item.title && `${item.title}.`}{" "}
                                {item.firstname} {item.lastname}
                              </td>
                              {item.position1 && (
                                <td
                                  className={`text-left py-[15px] pr-[20px] w-[40%] align-top`}
                                >
                                  {item.position1}
                                </td>
                              )}
                              <td
                                className={
                                  `text-left py-[15px] pr-[20px] align-top ` +
                                  (!item.position1 ? "w-[50%]" : "w-[40%]")
                                }
                              >
                                {item.position2}
                              </td>
                            </tr>
                          ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>

              <div className="h-[110px] bg-darkGray" />

              <div className="section">
                <div className="page-container">
                  <div className="py-[70px] flex flex-col">
                    <h1 className="text-[32px] sm:text-[40px] text-darkGray font-bold text-left uppercase">
                      Executive Committee from Korea
                    </h1>

                    <table className="w-full mt-[30px]">
                      <tbody>
                        {koreaData &&
                          koreaData.map((item, i) => (
                            <tr key={i}>
                              <td
                                className={
                                  `text-left py-[15px] pr-[20px] align-top ` +
                                  (!item.position1 ? "w-[50%]" : "w-[20%]")
                                }
                              >
                                {item.title && `${item.title}.`}{" "}
                                {item.firstname} {item.lastname}
                              </td>
                              {item.position1 && (
                                <td
                                  className={`text-left py-[15px] pr-[20px] w-[40%] align-top`}
                                >
                                  {item.position1}
                                </td>
                              )}
                              <td
                                className={
                                  `text-left py-[15px] pr-[20px] align-top ` +
                                  (!item.position1 ? "w-[50%]" : "w-[40%]")
                                }
                              >
                                {item.position2}
                              </td>
                            </tr>
                          ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>

              <div className="h-[110px] bg-darkGray" />

              <div className="section">
                <div className="page-container">
                  <div className="py-[70px] flex flex-col">
                    <h1 className="text-[32px] sm:text-[40px] text-darkGray font-bold text-left uppercase">
                      Executive Committee from Thailand
                    </h1>

                    <table className="w-full mt-[30px]">
                      <tbody>
                        {thaiData &&
                          thaiData.map((item, i) => (
                            <tr key={i}>
                              <td
                                className={
                                  `text-left py-[15px] pr-[20px] align-top ` +
                                  (!item.position1 ? "w-[50%]" : "w-[20%]")
                                }
                              >
                                {item.title && `${item.title}.`}{" "}
                                {item.firstname} {item.lastname}
                              </td>
                              {item.position1 && (
                                <td
                                  className={`text-left py-[15px] pr-[20px] w-[40%] align-top`}
                                >
                                  {item.position1}
                                </td>
                              )}
                              <td
                                className={
                                  `text-left py-[15px] pr-[20px] align-top ` +
                                  (!item.position1 ? "w-[50%]" : "w-[40%]")
                                }
                              >
                                {item.position2}
                              </td>
                            </tr>
                          ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>

              <div className="h-[110px] bg-darkGray" />

              <div className="section">
                <div className="page-container">
                  <div className="py-[70px] flex flex-col">
                    <h1 className="text-[32px] sm:text-[40px] text-darkGray font-bold text-left uppercase">
                      Academic Committee
                    </h1>

                    <table className="w-full mt-[30px]">
                      <tbody>
                        {academicData &&
                          academicData.map((item, i) => (
                            <tr key={i}>
                              <td
                                className={
                                  `text-left py-[15px] pr-[20px] align-top ` +
                                  (!item.position1 ? "w-[50%]" : "w-[20%]")
                                }
                              >
                                {item.title && `${item.title}.`}{" "}
                                {item.firstname}
                                {item.lastname}, {item.position2}
                              </td>
                              {item.position1 && (
                                <td
                                  className={`text-left py-[15px] pr-[20px] w-[40%] align-top`}
                                >
                                  {item.position1}
                                </td>
                              )}
                              {/* <td
                        className={
                          `text-left py-[15px] pr-[20px] align-top ` +
                          (!item.position1 ? "w-[50%]" : "w-[40%]")
                        }
                      >
                        {item.position2}
                      </td> */}
                            </tr>
                          ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>

              <div className="h-[110px] bg-darkGray" />

              <div className="section">
                <div className="page-container">
                  <div className="py-[70px] flex flex-col">
                    <h1 className="text-[32px] sm:text-[40px] text-darkGray font-bold text-left uppercase">
                      Organizing Committee
                    </h1>

                    <table className="w-full mt-[30px]">
                      <tbody>
                        {organizingData &&
                          organizingData.map((item, i) => (
                            <tr key={i}>
                              <td
                                className={
                                  `text-left py-[15px] pr-[20px] align-top ` +
                                  (!item.position1 ? "w-[50%]" : "w-[20%]")
                                }
                              >
                                {item.title && `${item.title}.`}{" "}
                                {item.firstname} {item.lastname}
                              </td>
                              {item.position1 && (
                                <td
                                  className={`text-left py-[15px] pr-[20px] w-[40%] align-top`}
                                >
                                  {item.position1}
                                </td>
                              )}
                              <td
                                className={
                                  `text-left py-[15px] pr-[20px] align-top ` +
                                  (!item.position1 ? "w-[50%]" : "w-[40%]")
                                }
                              >
                                {item.position2}
                              </td>
                            </tr>
                          ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div className="section h-[200px]"></div>
          )}
        </div>
      )}
    </div>
  );
}
