import http from "../http-common";
import HostModel from "../../models/about/host";

export default new (class HostService {
  async getHost() {
    return await http
      .get(
        "/hosts/2?filters[type][$eq]=1&fields[0]=type&fields[1]=name&fields[2]=url&populate=*"
      )
      .then(async (response) => {
        const data = await HostModel.get(response.data.data);

        return data;
      })
      .catch((error) => {
        console.log(error);
      });
  }

  async getCoHost() {
    return await http
      .get(
        "/hosts?filters[type][$eq]=2&fields[0]=type&fields[1]=name&fields[2]=url&populate=*"
      )
      .then(async (response) => {
        const data = await Promise.all(
          response.data.data.map((data) => HostModel.get(data))
        );

        return data;
      })
      .catch((error) => {
        console.log(error);
      });
  }
})();
