export default new (class ConferenceDetailModel {
  // static API_URL = process.env.REACT_APP_API_URL;

  async get(data) {
    const model = {};

    model.id = data.id || 0;
    model.name = data.attributes.conference_name || "";
    model.abbr = data.attributes.conference_abbr || "";
    model.detail = data.attributes.conference_detail || "";
    // model.image = data.attributes.host_image.data[0].attributes.url || "";

    return model;
  }
})();
