import React, { useEffect } from "react";
import venue from "../../assets/images/about/venue.png";
import bitec from "../../assets/images/about/bitec.png";
import PrimaryButton from "../../components/button/PrimaryButton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMap, faHouse } from "@fortawesome/free-solid-svg-icons";

export default function Venue() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  function venueNumber(number, text) {
    return (
      <div>
        <h1 className="font-bold text-[38px] md:text-[64px] text-darkGray">
          {number}
        </h1>
        <p className="font-semibold text-[15px] text-darkGray">{text}</p>
      </div>
    );
  }

  return (
    <div id="venue" className="page-padding">
      <div className="bg-darkGray section">
        <div className="page-container">
          <div className="flex items-center justify-center lg:justify-between pb-[80px] lg:pb-[0px] pt-[38px]">
            <h1 className="text-[32px] sm:text-[40px] md:text-[96px] text-white font-bold leading-[116px] text-center lg:text-left">
              VENUE
            </h1>
            <img
              className="hidden lg:block mt-[30px] w-[500px]"
              src={venue}
              alt=""
            />
          </div>
        </div>
      </div>

      <div className="bg-white flex flex-col xl:flex-row w-full">
        <div id="venue-number-box" className="px-[100px] py-[60px] w-full">
          <h1
            className="text-[32px] font-bold text-darkGray underline"
            onClick={() => window.open(`https://www.bitec.co.th/`, "_blank")}
          >
            Bangkok International Trade & <br />
            Exhibition Centre (BITEC)
          </h1>
          <div className="grid grid-cols-2 lg:grid-cols-3 gap-y-[50px] mt-[35px]">
            {venueNumber("11", "Restaurants Outlets")}
            {venueNumber("03", "Main Entrance")}
            {venueNumber("01", "Food Court FL.3")}
            {venueNumber("50", "Hotels ... Along BTS")}
            {venueNumber("2000+", "Hotels within working distance")}
            {venueNumber("4,030", "Total Parking Space")}
          </div>
        </div>
        <img
          className="max-h-[377px] object-cover w-full xl:w-[545px] z-10"
          src={bitec}
          alt=""
        />
      </div>

      <div className="bg-darkGray section">
        <div className="page-container">
          <div className="py-[90px]">
            <div className="grid grid-cols-1 lg:grid-cols-2 gap-[20px] mt-[50px] items-start">
              <div className="section flex-col">
                <div className="w-full py-[45px] bg-white section flex flex-col gap-[20px] sm:gap-[90px]">
                  <FontAwesomeIcon
                    icon={faMap}
                    className="w-[50px] h-[50px] md:w-[112px] md:h-[112px] text-darkGray"
                  />
                  <h1 className="font-bold text-[30px] md:text-[32px] sm:text-[40px] text-darkGray">
                    Journey to BITEC
                  </h1>
                  <PrimaryButton
                    text="read more"
                    underline={true}
                    function={() => {
                      window.open(
                        `https://www.bitec.co.th/visitor/getting-here`,
                        "_blank"
                      );
                    }}
                  />
                </div>
              </div>
              <div className="section flex-col">
                <div className="w-full py-[45px] bg-white section flex flex-col gap-[20px] sm:gap-[90px]">
                  <FontAwesomeIcon
                    icon={faHouse}
                    className="w-[50px] h-[50px] md:w-[112px] md:h-[112px] text-darkGray"
                  />
                  <h1 className="font-bold text-[30px] md:text-[32px] sm:text-[40px] text-darkGray">
                    Accommodation
                  </h1>
                  <PrimaryButton
                    text="read more"
                    underline={true}
                    function={() => {
                      window.open(
                        `https://www.bitec.co.th/visitor/hotels`,
                        "_blank"
                      );
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="h-[140px] bg-white" />
    </div>
  );
}
