import React from "react";
import comingSoonPic from "../assets/images/upcoming.png";

export default function ComingSoon(props) {
  return (
    <div className="bg-darkGray section py-[112px] pb-[80px] lg:pb-[20px] pt-[38px]">
      <div className="page-container">
        <h1 className="uppercase text-[32px] sm:text-[40px] md:text-[96px] font-bold text-white text-center">
          {props.title}
        </h1>
        <p className="font-bold text-white text-[11px] sm:text-[24px] md:text-[36px] text-center mt-[10px] md:mt-[36px]">
          APSS 2023 <span className="capitalize">{props.title}</span> will be
          updated soon​
        </p>
        <div className="section mt-[45px]">
          <img className="w-[70%] md:w-[600px]" src={comingSoonPic} alt="" />
        </div>
      </div>
    </div>
  );
}
