import React, { useEffect, useState } from "react";
import PrimaryButton from "../../components/button/PrimaryButton";
// import logo from "../../assets/images/logo.png";
import HostService from "../../services/about/host";
import tree from "../../assets/images/about/tree.png";
import tree2 from "../../assets/images/about/tree2.png";

import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";

// import required modules
import { Navigation } from "swiper";
function getWindowSize() {
  const { innerWidth, innerHeight } = window;
  return { innerWidth, innerHeight };
}

export default function Host() {
  useEffect(() => {
    window.scrollTo(0, 0);
    getHostData();
    getCoHostData();
  }, []);

  const [windowSize, setWindowSize] = useState(getWindowSize());

  useEffect(() => {
    function handleWindowResize() {
      setWindowSize(getWindowSize());
    }

    window.addEventListener("resize", handleWindowResize);

    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, []);

  let [hostData, setHostData] = useState([]);
  let [coHostData, setCoHostData] = useState([]);

  const getHostData = async () => {
    try {
      let res = await HostService.getHost();
      setHostData(res);
    } catch {
      console.error();
    }
  };

  const getCoHostData = async () => {
    try {
      let res = await HostService.getCoHost();
      setCoHostData(res);
    } catch {
      console.error();
    }
  };

  function CoHostCard(text, image, url) {
    return (
      <div className="relative bg-white h-full">
        <div className="section h-[200px] sm:h-[310px] bg-realWhite p-[10px] sm:p-[30px]">
          <img
            className="w-[50%] sm:w-full h-full object-contain"
            src={`${process.env.REACT_APP_API_URL}${image}`}
            alt=""
          />
        </div>
        <p className="mt-[25px] font-semibold text-[20px] p-[10px] sm:p-[25px] !pb-[100px]">
          {text}
        </p>
        <div
          onClick={() => {
            if (url)
              // eslint-disable-next-line
              window.open(`${url.replace(/[^\x00-\x7F]/g, "")}`, "_blank");
          }}
          className="absolute left-0 right-0 pt-[20px] pb-[30px] bottom-0 bg-darkRed underline font-bold text-white text-center text-[20px]"
        >
          {url ? (
            <span className="cursor-pointer">READ MORE</span>
          ) : (
            <span className="opacity-0 pointer-events-none">READ MORE</span>
          )}
        </div>
      </div>
    );
  }

  return (
    <div id="venue" className="page-padding">
      <div className="bg-darkGray section">
        <div className="page-container">
          <div className="relative section lg:py-[93px] pb-[80px] pt-[38px]">
            <img
              className="tree-host hidden lg:block absolute bottom-0 left-0 w-[386px]"
              src={tree}
              alt=""
            />
            <h1 className="text-[32px] sm:text-[40px] md:text-[96px] text-white font-bold leading-[116px] text-left">
              HOSTS
            </h1>
            <img
              className="tree-host hidden lg:block absolute bottom-0 right-0 w-[386px]"
              src={tree2}
              alt=""
            />
          </div>
        </div>
      </div>

      <div className="section">
        <div className="page-container">
          {hostData && (
            <div className="py-[60px] !pb-[30px] flex flex-col items-center">
              <div className="flex-wrap section md:divide-x gap-[10px] md:gap-[30px]">
                <img
                  className="w-[200px] md:w-[280px] lg:w-[345px]"
                  src={`${process.env.REACT_APP_API_URL}${hostData.image}`}
                  alt=""
                />
                <img
                  className="w-[200px] md:w-[280px] lg:w-[345px] pl-[30px]"
                  src={`${process.env.REACT_APP_API_URL}${hostData.image2}`}
                  alt=""
                />
              </div>
              <p className="text-[24px] font-semibold mt-[0px] max-w-[700px]">
                {hostData.name}
              </p>
              <div className="mt-[30px]">
                <PrimaryButton
                  text="READ MORE"
                  underline={true}
                  function={() => {
                    window.open(
                      // eslint-disable-next-line
                      `${hostData.url.replace(/[^\x00-\x7F]/g, "")}`,
                      "_blank"
                    );
                  }}
                />
              </div>
            </div>
          )}
        </div>
      </div>

      <div className="h-[80px] bg-darkGray" />

      <div className="bg-darkGray2 section">
        <div className="page-container">
          <div className="py-[93px]">
            <h1 className="text-[32px] sm:text-[40px] md:text-[96px] text-white font-bold leading-[116px] text-center">
              CO-HOSTS
            </h1>

            {coHostData.length && (
              <div className="section">
                <Swiper
                  navigation={windowSize.innerWidth >= 460 ? true : false}
                  modules={[Navigation]}
                  className="host-swiper mt-[27px] block xl:hidden"
                >
                  {coHostData.length &&
                    coHostData.map((item, i) => (
                      <SwiperSlide key={i}>
                        <div>{CoHostCard(item.name, item.image, item.url)}</div>
                      </SwiperSlide>
                    ))}
                  {/* <div class="swiper-button-prev"></div>
                <div class="swiper-button-next"></div> */}
                </Swiper>
              </div>
            )}

            <div className="hidden xl:grid grid-cols-4 gap-[20px] mt-[80px]">
              {coHostData.length &&
                coHostData.map((item, i) => (
                  <div key={i}>
                    {CoHostCard(item.name, item.image, item.url)}
                  </div>
                ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
