import React from "react";

export default function SecondaryButton(props) {
  return (
    <div
      className={
        `whitespace-nowrap cursor-pointer w-fit uppercase text-[18px] md:text-[20px] font-bold py-[13px] px-[27px] bg-white text-darkGray ` +
        (props.underline && "underline")
      }
      onClick={() => {
        props.function && props.function();
      }}
    >
      {props.text}
    </div>
  );
}
