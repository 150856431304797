import React, { useEffect, useState } from "react";
import submissionPic from "../../assets/images/submission/submission.png";
import girl1 from "../../assets/images/submission/girl.png";
import girl2 from "../../assets/images/submission/girl2.png";
import alert from "../../assets/images/submission/alert.svg";
import close from "../../assets/images/submission/fi-rs-cross-small.svg";
import SecondaryButton from "../../components/button/SecondaryButton";
import ComingSoon from "../../components/ComingSoon";
import HomeService from "../../services/home";

export default function Submission() {
  const active = true;
  let [conferenceFiles, setConferenceFiles] = useState(null);
  let [fullTemplateFiles, setFullTemplateFiles] = useState(null);
  const [showButton, setShowButton] = useState(true);

  useEffect(() => {
    // check if the user has already closed the alert
    const isAlertClosed = localStorage.getItem("isAlertClosed");
    if (isAlertClosed === "true") {
      setShowButton(false);
    }
  }, []);

  function handleButtonClick() {
    // set the value in local storage to 'true'
    localStorage.setItem("isAlertClosed", "true");
    setShowButton(false);
  }

  useEffect(() => {
    window.scrollTo(0, 0);
    getConferenceFiles();
    getFullTemplateFiles();
  }, []);

  const getFullTemplateFiles = async () => {
    try {
      let res = await HomeService.getFullTemplateFiles();
      setFullTemplateFiles(res);
    } catch {
      console.error();
    }
  };

  const getConferenceFiles = async () => {
    try {
      let res = await HomeService.getConferenceFiles();
      setConferenceFiles(res);
    } catch {
      console.error();
    }
  };

  // function submissionCard(image, text, btnText) {
  //   return (
  //     <div className="bg-darkRed py-[56px] px-[47px] section flex-col w-full">
  //       <div>
  //         <img className="w-[275px]" alt="" src={image} />
  //       </div>
  //       <p className="font-bold text-white text-[20px] text-center mb-[58px] mt-[25px]">
  //         {text}​
  //       </p>
  //       <SecondaryButton text={btnText} />
  //     </div>
  //   );
  // }

  return (
    <div id="programme" className="page-padding">
      {!active ? (
        <ComingSoon title="submission" />
      ) : (
        <div>
          <div className="bg-darkGray section">
            <div className="page-container">
              <div className="flex items-center justify-center lg:justify-between py-[18px]">
                <h1 className="text-[32px] sm:text-[40px] md:text-[96px] text-white font-bold leading-[116px] text-center lg:text-left">
                  SUBMISSION
                </h1>
                <img
                  className="w-[370px] hidden lg:block"
                  src={submissionPic}
                  alt=""
                />
              </div>
            </div>
          </div>

          <div className="section py-[68px]">
            <div className="page-container">
              {showButton && (
                <div className="mb-[40px] mx-[0px] md:mx-[40px] flex items-center justify-between py-[15px] px-[24px] rounded-[16px] bg-lightBrown2 border-[1px] border-red">
                  <div className="gap-[20px] flex items-center justify-center">
                    <img src={alert} alt="" />
                    <p className="text-[14px] lg:text-[18px] text-red text-left">
                      If you got any problem about your abstract submission,
                      please contact staff by e-mail and attach your abstract
                      file to{" "}
                      <span
                        className="text-blue2 underline cursor-pointer"
                        onClick={() => {
                          window.location.href = "mailto:chemeng@kmutt.ac.th";
                        }}
                      >
                        chemeng@kmutt.ac.th
                      </span>{" "}
                      . We will response you as fast as possible.
                    </p>
                  </div>
                  <div>
                    <img
                      className="w-[35px] cursor-pointer"
                      src={close}
                      alt=""
                      onClick={handleButtonClick}
                    />
                  </div>
                </div>
              )}
              <div className="flex flex-col lg:flex-row gap-[20px]">
                <div className="bg-darkRed py-[56px] px-[20px] md:px-[47px] section flex-col w-full">
                  <div>
                    <img
                      className="w-[150px] sm:w-[200px] md:w-[275px]"
                      alt=""
                      src={girl1}
                    />
                  </div>
                  <p className="font-bold text-white text-[20px] text-center mb-[58px] mt-[25px]">
                    The abstract submission will be available on 15 January
                    2023​
                  </p>
                  <div className="justify-center items-center flex flex-col w-full">
                    {conferenceFiles && (
                      <SecondaryButton
                        text={"abstract template"}
                        underline={true}
                        function={() => {
                          window.open(
                            `${process.env.REACT_APP_API_URL}${conferenceFiles.attributes.file.data[0].attributes.url}`,
                            "_self"
                          );
                        }}
                      />
                    )}
                    <div className="mt-[10px]">
                      <SecondaryButton
                        text={"abstract submission"}
                        underline={true}
                        function={() => {
                          window.open(
                            "https://apss2023.innosoft.kmutt.ac.th/conference",
                            "_blank"
                          );
                        }}
                      />
                    </div>
                  </div>
                </div>

                <div className="bg-darkRed py-[56px] px-[20px] md:px-[47px] section flex-col w-full">
                  <div>
                    <img
                      className="w-[150px] sm:w-[200px] md:w-[275px]"
                      alt=""
                      src={girl2}
                    />
                  </div>
                  <p className="font-bold text-white text-[20px] text-center mb-[58px] mt-[25px]">
                    After the abstract acceptance, The author will be requested
                    to submit the full manuscript
                  </p>
                  {fullTemplateFiles && (
                    <SecondaryButton
                      underline={true}
                      text="full paper template"
                      function={() => {
                        window.open(
                          `${process.env.REACT_APP_API_URL}${fullTemplateFiles?.attributes?.file?.data?.attributes.url}`,
                          "_self"
                        );
                      }}
                    />
                  )}
                  {/* <p className="font-bold text-white text-[20px] text-center mt-[10px]">
                    Coming soon
                  </p> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
