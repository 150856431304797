import http from "../http-common";
import ContactModel from "../../models/about/download";

export default new (class DownloadService {
  async getDownload() {
    return await http
      .get("/documents?fields[0]=filename&populate=*")
      .then(async (response) => {
        const data = await Promise.all(
          response.data.data.map((item) => ContactModel.get(item))
        );

        return data;
      })
      .catch((error) => {
        console.log(error);
      });
  }
})();
