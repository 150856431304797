import React, { useEffect, useState } from "react";
import ComingSoon from "../../components/ComingSoon";
import PartnersService from "../../services/partners";

export default function Sponsors() {
  const active = true;
  let [platinumData, setPlatinumData] = useState(null);
  let [goldData, setGoldData] = useState(null);
  let [silverData, setSilverData] = useState(null);

  useEffect(() => {
    window.scrollTo(0, 0);
    getPlatinumData();
    getGoldData();
    getSilverData();
  }, []);

  const getPlatinumData = async () => {
    try {
      let res = await PartnersService.getPartners(1);
      setPlatinumData(res);
    } catch {
      console.error();
    }
  };

  const getGoldData = async () => {
    try {
      let res = await PartnersService.getPartners(2);
      setGoldData(res);
    } catch {
      console.error();
    }
  };

  const getSilverData = async () => {
    try {
      let res = await PartnersService.getPartners(3);
      setSilverData(res);
    } catch {
      console.error();
    }
  };

  function circleImg(size, item) {
    return (
      <div
        className={
          `rounded-full bg-realWhite section ` +
          (size === "l"
            ? "w-[150px] h-[150px] lg:w-[200px] lg:h-[200px]"
            : size === "m"
            ? "w-[120px] h-[120px] lg:w-[173px] lg:h-[173px]"
            : "w-[100px] h-[100px] lg:w-[146px] lg:h-[146px]")
        }
      >
        {item && (
          <img
            onClick={() => {
              if (item && item.url)
                // eslint-disable-next-line
                window.open(
                  // eslint-disable-next-line
                  `${item.url.replace(/[^\x00-\x7F]/g, "")}`,
                  "_blank"
                );
            }}
            className="w-full"
            src={`${process.env.REACT_APP_API_URL}${item.image}`}
            alt=""
          />
        )}
      </div>
    );
  }

  return (
    <div id="programme" className="page-padding">
      {!active ? (
        <ComingSoon title="partners" />
      ) : (
        <div>
          <div className="bg-darkGray section">
            <div className="page-container">
              <div className="flex items-center justify-center py-[28px] pb-[80px] lg:pb-[0px] pt-[38px]">
                <div>
                  <h1 className="text-[32px] sm:text-[40px] md:text-[96px] text-white font-bold leading-[116px] text-center">
                    PARTNERS
                  </h1>
                  <h1 className="uppercase text-white font-bold text-[32px] sm:text-[40px] mt-[160px]">
                    Platinum Partners
                  </h1>
                </div>
              </div>
            </div>
          </div>

          <div className="section py-[110px]">
            <div className="page-container">
              <div className="flex gap-[20px] md:gap-[30px] lg:gap-[70px] justify-center flex-wrap">
                {platinumData && platinumData.length > 0 ? (
                  <>
                    {platinumData.map((item, i) => (
                      <div key={i}>{circleImg("l", item)}</div>
                    ))}
                    {[...Array(3 - platinumData.length)].map((_, i) => (
                      <div key={platinumData.length + i}>
                        {circleImg("l", undefined)}
                      </div>
                    ))}
                  </>
                ) : (
                  [...Array(3)].map((_, i) => (
                    <div key={i}>{circleImg("l", undefined)}</div>
                  ))
                )}
              </div>
            </div>
          </div>

          <div className="bg-darkGray section pt-[240px] pb-[50px]">
            <div className="page-container">
              <h1 className="uppercase text-white font-bold text-[32px] sm:text-[40px]">
                Gold Partners
              </h1>
            </div>
          </div>

          <div className="section py-[110px]">
            <div className="page-container">
              <div className="flex gap-[20px] md:gap-[30px] lg:gap-[70px] justify-center flex-wrap">
                {goldData && goldData.length > 0 ? (
                  <>
                    {goldData.map((item, i) => (
                      <div key={i}>{circleImg("m", item)}</div>
                    ))}
                    {[...Array(5 - goldData.length)].map((_, i) => (
                      <div key={goldData.length + i}>
                        {circleImg("m", undefined)}
                      </div>
                    ))}
                  </>
                ) : (
                  [...Array(5)].map((_, i) => (
                    <div key={i}>{circleImg("m", undefined)}</div>
                  ))
                )}
              </div>
            </div>
          </div>

          <div className="bg-darkGray section pt-[240px] pb-[50px]">
            <div className="page-container">
              <h1 className="uppercase text-white font-bold text-[32px] sm:text-[40px]">
                Silver Partners
              </h1>
            </div>
          </div>

          <div className="section py-[110px]">
            <div className="page-container section">
              <div className="flex flex-wrap lg:grid lg:grid-cols-5 gap-[20px] md:gap-[30px] lg:gap-[70px] justify-center">
                {silverData && silverData.length > 0 ? (
                  <>
                    {silverData.map((item, i) => (
                      <div key={i}>{circleImg("s", item)}</div>
                    ))}
                    {[...Array(9 - silverData.length)].map((_, i) => (
                      <div key={silverData.length + i}>
                        {circleImg("s", undefined)}
                      </div>
                    ))}
                  </>
                ) : (
                  [...Array(9)].map((_, i) => (
                    <div key={i}>{circleImg("s", undefined)}</div>
                  ))
                )}
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
