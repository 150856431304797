import http from "../http-common";
import ContactModel from "../../models/about/contact";

export default new (class ContactService {
  async getContact() {
    return await http
      .get(
        "/conference-details/1?fields[0]=host_name&fields[1]=host_phone_number&fields[2]=host_address&fields[3]=host_url&populate=*"
      )
      .then(async (response) => {
        const data = await ContactModel.get(response.data.data);

        return data;
      })
      .catch((error) => {
        console.log(error);
      });
  }

  async addContact(data) {
    return await http
      .post("/contact-messages", { data: data })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        return error.response;
      });
  }
})();
